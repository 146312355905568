import "./src/styles/tailwind.css"

import React from "react"

import ReduxWrapper from "./src/state/ReduxWrapper"

window.decomp = require(`poly-decomp`)

export const wrapRootElement = props => {
  return <ReduxWrapper {...props} />
}

// Scroll the page to the top on each route change
export const onRouteUpdate = ({ location }) => {
  if (location.action === `PUSH`) {
    window.setTimeout(() => window.scrollTo(0, 0), 10)
  }
}

export const onInitialClientRender = () => {
  const referrer = document.referrer
  const landing = document.URL
  const isUTM = /(&|\?)utm([_a-z0-9+-]+)/g.test(landing)
  const isGclid = /gclid=(\w+)/.test(landing)
  const isMsclkid = /msclkid=(\w+)/.test(landing)

  if (sessionStorage && localStorage) {
    if (isUTM) {
      localStorage.setItem(`landing`, landing)
    }

    if (isGclid) {
      const gclid = landing.match(/gclid=(\w+)/)
      sessionStorage.setItem(`gclid`, gclid[1])
    }

    if (isMsclkid) {
      const msclkid = landing.match(/msclkid=(\w+)/)
      sessionStorage.setItem(`msclkid`, msclkid[1])
    }

    if (landing && !sessionStorage.getItem(`landing`)) {
      sessionStorage.setItem(`landing`, landing)
    }

    if (referrer && !sessionStorage.getItem(`referrer`)) {
      sessionStorage.setItem(`referrer`, referrer)
    }
  }
}
